import React, { useEffect } from "react"
import { useQuery } from "@apollo/client"
// our packages
import { AntsCongratsReferredInfo, RegistrationCompleteInfo } from "@components"
import { TRACKING_ATM } from "@constants"
import { SignupLayout } from "@layout"
import { QUERY_USER, usePublishBrazeEvent, usePublishWebEvent } from "@services"
import { storageRemoveUserRegistration } from "@session"
import { GQLUser } from "@types"

const SignupVerifyIdentity = () => {
  const [publishWebEvent] = usePublishWebEvent()
  const [publishBrazeEvent, { brazeSdk }] = usePublishBrazeEvent()

  storageRemoveUserRegistration()

  useEffect(() => {
    publishWebEvent({ name: TRACKING_ATM.screen_view_reg_success })
  }, [])

  useQuery(QUERY_USER, {
    onCompleted: (data) => {
      const user: GQLUser = data.user
      brazeSdk.changeUser(user.id)
      brazeSdk.getUser().setEmail(user.email)
      brazeSdk.getUser().setFirstName(user.firstName)
      brazeSdk.getUser().setLastName(user.lastName)
      brazeSdk.getUser().setPhoneNumber(user.phone)
      brazeSdk.getUser().setCustomUserAttribute("web_reg", true)
    },
  })

  return (
    <SignupLayout
      hideJoinDownload
      navBarNoLinks
      leftContent={<RegistrationCompleteInfo />}
      rightContent={<AntsCongratsReferredInfo />}
    ></SignupLayout>
  )
}

export default SignupVerifyIdentity
